<template>
  <container ref="container" @error="init">
    <!-- 服务介绍 -->
    <publicSection
      class="store"
      :header="require('@/assets/image/store/physicalStore.png')"
    >
      <!-- 门店-导航 -->
      <div class="store-nav">
        <div
          class="store-nav-element"
          :class="{ 'store-nav-element_active': store.index == index }"
          v-for="(item, index) in store.nav"
          :key="item"
          @click="storeChange(index)"
        >
          {{ item }}
        </div>
      </div>

      <!-- 门店-列表 -->
      <container ref="storeList" @error="getData">
        <!-- 门店-元素 -->
        <div class="store-list">
          <div
            class="store-item"
            v-for="(item, index) in store.list"
            :key="index"
          >
            <div class="store-item-cover">
              <img class="store-item-cover-image" :src="item.mdimage" />
              <div class="store-item-name">{{ item.name }}</div>
            </div>
            <div class="store-item-phone">电话：{{ item.telephone }}</div>
            <div class="store-item-location">地址：{{ item.addressinfo }}</div>
          </div>
        </div>
      </container>
    </publicSection>
  </container>
</template>

<script>
import publicSection from "@/components/publicSection.vue";
export default {
  name: "store",
  components: {
    publicSection,
  },
  data() {
    return {
      store: {
        nav: ["深圳", "上海", "哈尔滨"],
        index: 0,
        list: [],
      },
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * 初始化数据
     */
    async init() {
      this.getData();
    },
    /**
     * 获取数据
     */
    async getData() {
      try {
        // 设置状态
        this.$refs.storeList.status("init", {
          height: "50vh",
        });

        // 获取数据
        const result = await this.$axios("/api/mainstation/companyStore", {
          params: {
            city: this.store.nav[this.store.index],
          },
        });

        // 设置数据
        this.store.list = result.data;

        // 设置状态
        this.$refs.storeList.status("default");
      } catch (e) {
        // 设置状态
        this.$refs.storeList.status("error", {
          text: e.message,
          height: "100vh",
        });
      }
    },
    /**
     * 设置轮播
     */
    storeChange(index) {
      // 切换标签
      this.store.index = index;

      // 获取数据
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  // 门店
  .store {
    margin: 50px 0;

    // 门店-导航
    &-nav {
      display: flex;
      justify-content: center;
      width: 1200px;
      margin: auto;
      margin-top: 20px;

      &-element {
        width: 122px;
        height: 58px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ed207b;
        color: #ed207b;
        margin-right: 130px;

        &:last-of-type {
          margin-right: 0;
        }

        &_active {
          background-color: #ed207b;
          color: #fff;
        }
      }
    }

    // 商店-轮播
    &-swiper {
    }

    // 商店-列表
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: 30px auto;
      width: 1200px;
    }

    // 商店-元素
    &-item {
      width: rpx(180);
      height: rpx(180);
      overflow: hidden;
      border: rpx(1) solid #bfbfbf;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      margin-bottom: 30px;

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      &-cover {
        position: relative;
        height: rpx(130);

        &-image {
          @include cover;
        }
      }
      
      &-name {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #ed207b, $alpha: 0.6);
        color: #fff;
        font-size: rpx(10);
        line-height: rpx(20);
        height: rpx(20);
        padding: 0 rpx(10);
      }

      &-phone {
        line-height: rpx(10);
        font-size: rpx(10);
        margin: 0 rpx(10);
        margin-top: rpx(8);
      }

      &-location {
        line-height: rpx(10);
        font-size: rpx(10);
        margin: rpx(10);
        margin-top: rpx(8);
        @include ellipsis;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  // 门店
  .store {
    padding: rpx(50) 0;

    // 门店-导航
    &-nav {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: auto;
      margin-top: 20px;

      &-element {
        width: rpx(120);
        height: rpx(60);
        font-size: rpx(24);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: rpx(2) solid #ed207b;
        color: #ed207b;
        margin-right: rpx(80);

        &:last-of-type {
          margin-right: 0;
        }

        &_active {
          background-color: #ed207b;
          color: #fff;
        }
      }
    }

    // 商店-轮播
    &-swiper {
    }

    // 商店-列表
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: rpx(30);
    }

    // 商店-元素
    &-item {
      width: rpx(326);
      height: rpx(340);
      overflow: hidden;
      border: rpx(2) solid #bfbfbf;
      border-radius: rpx(15);
      display: flex;
      flex-direction: column;
      margin-right: rpx(30);
      margin-bottom: rpx(30);

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &-cover {
        position: relative;
        height: rpx(220);

        &-image {
          @include cover;
        }
      }
      
      &-name {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #ed207b, $alpha: 0.6);
        color: #fff;
        font-size: rpx(20);
        line-height: rpx(40);
        height: rpx(40);
        padding: 0 rpx(20);
      }

      &-phone {
        line-height: rpx(28);
        font-size: rpx(20);
        margin: 0 rpx(20);
        margin-top: rpx(20);
      }

      &-location {
        line-height: rpx(28);
        font-size: rpx(20);
        margin: rpx(20);
        margin-top: rpx(20);
        @include ellipsis;
      }
    }
  }
}
</style>
